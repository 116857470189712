.button-secundary-gibobs,
.button-secundary-gibobs > button {
	border-color: #02c3cd;
	border-radius: 4px;
	color: #02c3cd;
	width: auto;
	height: 32px;
	font-size: 14px;
	font-weight: 500;
	padding: 0 10px;
}

.button-primari-gibobs {
	background-color: #02c3cd;
	border-radius: 4px;
	color: #ffff;
	width: auto;
	height: 32px;
	font-size: 14px;
	text-shadow: none;
	box-shadow: none;
	padding: 0 10px;
}
.button-primari-delete-gibobs {
	background-color: #ff4d4f;
	border-radius: 4px;
	color: #ffff;
	height: 40px;
	font-size: 14px;
	text-shadow: none;
	box-shadow: none;
	padding: 0 10px;
}
.button-secondary-delete-gibobs {
	border-color: #ff4d4f;
	border-radius: 4px;
	color: #ff4d4f;
	width: auto;
	height: 32px;
	font-size: 14px;
	font-weight: 500;
	padding: 0 10px;
	&:hover {
		border-color: #ff4d4f !important;
		color: #ff4d4f !important;
		filter: brightness(110%);
	}
}
.gb-modal-style-common {
	.ant-modal-title {
		font-size: 14px;
		font-weight: 600;
		color: #2f4858;
	}
	.ant-modal-content {
		padding: 0px !important;
	}
	.ant-modal-header {
		border-bottom: none;
		padding-left: 40px;
		padding-top: 40px;
		padding-bottom: 16px;
		padding-right: 40px;
	}
	.ant-modal-body {
		padding-left: 40px;
		padding-right: 40px;
		padding-top: 0px;
	}
	.ant-modal-footer {
		padding-top: 0;
		padding-right: 40px;
		padding-left: 40px;
		padding-bottom: 40px;
		border-top: none;
		text-align: center;
	}
	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 22px;
		padding-right: 38px;
	}
}

.gb-modal-40-padding {
	.ant-modal-header {
		padding-top: 40px;
		padding-right: 40px;
		padding-left: 40px;
		border-bottom: none;
	}
	.ant-modal-body {
		padding-top: 0px;
		padding-right: 40px;
		padding-left: 40px;
	}

	.ant-modal-title {
		font-size: 16px;
		font-weight: 600;
		color: #2f4858;
	}

	.ant-modal-footer {
		display: flex;
		padding-bottom: 40px;
		padding-top: 24px;
		border-top: none;
		place-content: center;
	}
	.ant-modal-close-x {
		padding-top: 24px;
		padding-right: 40px !important;
		color: #02c3cd;
	}
}

.gb-steps-styles-common {
	margin-left: 24px;

	.ant-steps-item-description {
		width: 100%;
		text-align: center;
	}
	.ant-steps-item-tail {
		width: 9px;
		text-align: center;
	}

	.ant-steps-item-finish {
		.ant-steps-item-icon {
			background: white;
			border: 2px solid #52c41a !important;

			.ant-steps-icon {
				display: inline;
				color: #52c41a;
				top: -2px;
			}
			.ant-steps-item-content {
				margin-left: -25px;
			}
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: #52c41a;
					text-align: left;
				}
			}
		}
	}

	.ant-steps-item-process {
		.ant-steps-item-container {
			.ant-steps-item-icon {
				background: white;
				border: 2px solid #02c3cd;
			}

			.ant-steps-item-description {
				color: #02c3cd;
				text-align: center !important;
			}
		}
	}

	.ant-steps-item-wait {
		.ant-steps-item-icon {
			border: 2px solid #c0daee;
			.ant-steps-icon {
				display: none;
			}
		}
		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: #c0daee;
					font-weight: normal;
				}
			}
		}
	}
}

.gb-steps-styles-common-email-banks {
	margin-left: 24px;

	.ant-steps-item-description {
		width: 100%;
		text-align: center;
	}
	.ant-steps-item-tail {
		width: 9px;
		text-align: center;
	}

	.ant-steps-item-wait {
		.ant-steps-item-icon {
			border: 2px solid #c0daee;
			.ant-steps-icon {
				display: none;
			}
		}
		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: #c0daee;
					font-weight: normal;
				}
			}
		}
	}
}

.gb-card-desktop {
	background: #ffffff;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	border-radius: 4px;
	width: calc(100% - 40px);
	.ant-card-body {
		padding: 32px;
	}
}

.gb-card-desktop-24px {
	background: #ffffff;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	border-radius: 4px;
	width: calc(100% - 40px);
	.ant-card-body {
		padding: 24px;
	}
}

.new-style-layout-tools {
	background: #effcfc;
	border-radius: 4px;
	width: auto;
	border-bottom: none;
	padding: 8px;
	display: flex;
	flex-wrap: wrap;
}

.modal-task-button-style {
	.ant-modal-content {
		border-radius: 0px;
		padding: 0px;
	}
	.ant-modal-footer {
		text-align: center;
		padding-left: 40px;
	}
}
.error-notification-gibobs {
	border: 1px solid #ff7a7b;
	background: #ffdede;
	border-radius: 4px;
	.ant-notification-notice-message {
		color: #2f4858;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
	}
	.ant-notification-notice-description {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #2f4858;
	}
	.ant-notification-notice-close {
		height: 16px;
		width: 16px;
		color: #2f4858;
	}
}
.gb-text-600 {
	font-size: 14;
	font-weight: 600;
	color: '#2F4858';
}
.gb-text-400 {
	font-size: 12;
	font-weight: 400;
	color: '#2F4858';
}

.notification-success {
	.ant-notification-notice {
		background: #7dd353 !important ;
		max-width: 280px !important;
		height: 56px !important;
	}
}

.gb-modal-create-note {
	.ant-modal-content {
		height: auto;
		min-height: 600px;
		padding: 40px !important;
	}
	.ant-modal-body {
		height: auto;
		min-height: 600px;
	}
}
.gb-modal-create-note-image {
	.ant-modal-content {
		height: auto;
		min-height: 100px;
		padding: 40px !important;
	}
	.ant-modal-body {
		height: auto;
		min-height: 100px;
	}
}

.tableCluster {
	.ant-table-tbody {
		.ant-table-measure-row {
			.ant-table-row .ant-table-row-level-0 {
				background: #e1f7ff;
			}
			.ant-table-row {
				background: #e1f7ff;
				.ant-table-row-level-0 {
					background: #e1f7ff;
				}
			}
		}
	}
}

.form-anonymize {
	.ant-select-selector {
		border-radius: 4px !important;
	}
}
