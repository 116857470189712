.gibobs-card-resume {
	.ant-card-body {
		height: 100%;
	}
}

.gibobs-pop-over-resume {
	.ant-popover-inner-content {
		padding: 24px;
	}
}

.gb-form-task {
	.ant-col {
		.ant-form-item-label {
			label {
				height: 100%;
				font-size: 12px;
				color: #2f4858;
				font-weight: 400;
				width: 113px;
			}
		}
		.ant-form-item-control {
			.ant-form-item-control-input {
				.ant-radio-wrapper {
					font-size: 14px;
					color: #748ea0;
					font-weight: 400;
				}
				.ant-checkbox-wrapper {
					font-size: 14px;
					color: #748ea0;
					font-weight: 400;
				}
				.ant-picker {
					width: 100%;
					border: 1px solid rgb(192, 218, 238);
					border-radius: 4px;
				}
			}
		}
	}

	.ant-input {
		height: 32px;
		border-radius: 4px;
		border-color: #c0daee;
		font-size: 12px;
		width: 100%;
		// text-align-last: center;
	}
	.ant-picker-input {
		input {
			height: 20px;
			font-size: 12px;
			color: #2f4858;
			font-weight: 400;
		}
	}
}
.notification-gibobs {
	border: 1px solid #fbc14f;
	background: #fef0d3;
	border-radius: 4px;
	padding-bottom: 0px;
	.ant-notification-notice-message {
		color: #2f4858;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
	}
	.ant-notification-notice-description {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #2f4858;
	}
	.ant-notification-notice-close {
		display: none !important;
	}
}

.button-gibobs-notifications {
	position: absolute;
	top: 14px;
	right: 28px;
	background-color: transparent;
	color: #2f4858;
	border: none;
	font-size: 17px;
}

.card-green-mortgage {
	.ant-card-head {
		background-color: #52c41a;
		height: 28px;
	}
}

.card-amigobs {
	border: 1px solid #016b71 !important;
	.ant-card-head {
		background-color: #016b71;
		height: 28px;
		border-top-left-radius: 4px !important;
		border-top-right-radius: 4px !important;
	}
}

.card-challenge {
	border: 1px solid #901594 !important;
	.ant-card-head {
		background-color: #901594;
		height: 28px;
		border-top-left-radius: 4px !important;
		border-top-right-radius: 4px !important;
	}
}

.advanced-search-card {
	background: #effcfc;
	border: 1px solid #c0f0f3;
	border-radius: 0px 0px 4px 4px;
	.wrapper-button {
		justify-content: center;
		display: flex;
		column-gap: 8px;
		margin-top: 8px;
	}
	.title-advanced-search {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		color: #2f4858;
		margin-bottom: 20px;
	}
	.ant-form label {
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: #2f4858;
	}
	.ant-input {
		height: 40px;
		border-radius: 4px;
		border-color: #c0daee;
		font-size: 14px;
		width: 100%;
	}
	.ant-input-number-input {
		height: 40px;
		padding: 0 11px;
		text-align: left;
		font-size: 14px;
		outline: 0;
	}
}
.gb-form-offers-new {
	.ant-form-item {
		margin-bottom: 8px !important;
		.ant-form-item-label {
			line-height: 12px;
			margin-bottom: 0px;
			label {
				font-size: 12px;
				color: #2f4858;
				font-weight: 400;
			}
		}
		.ant-input-number{
			height: 40px !important;
		}
		
		.ant-input-affix-wrapper {
			height: 40px;
			color: #748ea0;
			font-size: 14px;
			line-height: 20px;
			width: 100%;
			border-color: #c0daee;
			border-radius: 4px;
			input {
				height: 30px;
			}
		}
		.ant-form-item-control {
			.ant-input-affix-wrapper {
				input {
					height: 30px;
				}
			}
			input {
				height: 40px;
				color: #748ea0;
				font-size: 14px;
				line-height: 20px;
				width: 100%;
				border-color: #c0daee;
				border-radius: 4px;
			}
			
			.ant-radio-wrapper {
				color: #2f4858;
				font-size: 12px;
				font-weight: 400;
			}
			.ant-checkbox-group, .ant-checkbox-wrapper {
				label, span {
					color: #748ea0;
					font-size: 14px !important;
					font-weight: 400;
				}
			}
			.ant-radio-group {
				label {
					color: #748ea0;
					font-size: 14px !important;
					font-weight: 400;
				}
			}
			.ant-form-item-explain-error {
				font-size: 12px;
				margin-top: 3px;
				margin-bottom: 3px;
			}

			.ant-form-item-control-input-content {
				textarea {
					color: #748ea0;
					font-size: 14px !important;
				}
			}
			.ant-select {
				height: 40px;
			}
			.ant-select-selector {
				width: 100%;
				border-color: #c0daee;
				border-radius: 4px !important;
				.ant-select-selection-item {
					align-self: center;
					font-size: 14px;
					color: #748ea0;
				}
				.ant-select-selection-placeholder {
					height: 40px;
					font-size: 14px;
					padding: 4px 0;
				}
			}
		}
	}
}
.certificates-form {
	.ant-form-item {
		.ant-form-item-control {
			.ant-select-selector {
				height: 40px;
				width: 100%;
				.ant-select-selection-item {
					align-self: center;
					font-size: 14px;
				}
				.ant-select-selection-placeholder {
					height: 40px;
					font-size: 14px;
					padding: 4px 0;
				}
			}
		}
	}
}
.gb-offer-input-new {
	.ant-input-number {
		border-color: #c0daee;
		border-radius: 4px !important;
		font-size: 12px;
		margin-bottom: 0;
		height: 40px;
		width: 100%;
		line-height: 40px;
		width: 100%;
	}
	.ant-select-selector {
		border-radius: 4px !important;
		width: 100% !important;
	}
	.ant-form-item-label {
		label {
			font-weight: normal;
			font-size: 12px;
			color: #2f4858;
		}
	}
	.ant-col {
		margin-bottom: 0 !important;
	}
	.ant-dropdown-trigger {
		border-color: #c0daee;
		border-radius: 4px;
		font-size: 12px;
		margin-bottom: 0;
	}
	.ant-select-single .ant-select-selector .ant-select-selection-item {
		line-height: 40px;
	}
	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		height: 40px;
	}
	.gb-input-number {
		padding-inline-start: 0px !important;
	}
	.ant-checkbox .ant-checkbox-inner {
		background-color: #fcf7ec;
		border-color: #f5cd57;
	}
	.ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
		background-color: #25d6d9;
	}
}

.gb-select-offer-new {
	.ant-dropdown-trigger {
		border-color: #c0daee;
		border-radius: 4px;
		font-size: 12px;
		margin-bottom: 0;
	}
	.ant-form-item-label {
		label {
			font-weight: normal;
			font-size: 12px;
			color: #2f4858;
		}
	}
	.ant-col {
		margin-bottom: 0 !important;
	}
	.ant-select-selector {
		border-radius: 4px !important;
	}
	.ant-select-single .ant-select-selector .ant-select-selection-item {
		line-height: 40px;
	}
	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		height: 40px;
	}
}

.bf-content {
	height: auto;
}

.card-sabadell {
	.ant-card-head {
		background-color: #006dff;
		height: 28px;
		border-radius: 4px 4px 0px 0px;
	}
}
.card-sabadell-idealista {
	.ant-card-head {
		background-color: #ddf164;
		height: 28px;
		border-radius: 4px 4px 0px 0px;
	}
}

.card-sabadell-helloteca {
	.ant-card-head {
		background-color: #81c8d2;
		height: 28px;
		border-radius: 4px 4px 0px 0px;
	}
}
.card-sabadell-housfy {
	.ant-card-head {
		background-color: #4aa2ec;
		height: 28px;
		border-radius: 4px 4px 0px 0px;
	}
}
.card-sabadell-iahorro {
	.ant-card-head {
		background-color: #173259;
		height: 28px;
		border-radius: 4px 4px 0px 0px;
	}
}
.card-sabadell-rastreator {
	.ant-card-head {
		background-color: #4397bd;
		height: 28px;
		border-radius: 4px 4px 0px 0px;
	}
}
.card-sabadell-trioteca {
	.ant-card-head {
		background-color: #14213c;
		height: 28px;
		border-radius: 4px 4px 0px 0px;
	}
}
.card-sabadell-wypo {
	.ant-card-head {
		background-color: #e75e50;
		height: 28px;
		border-radius: 4px 4px 0px 0px;
	}
}

.card-sabadell-gibobs {
	.ant-card-head {
		background: linear-gradient(90deg, #0088C6 0%, #00B298 100%);
		height: 28px;
		border-radius: 4px 4px 0px 0px;
	}
}

.gb-gibobs-label-10 {
	.ant-form-item {
		label {
			font-size: 10px !important;
			color: #2f4858;
			font-weight: 400;
		}
	}
}

.info-payments-expenses-detail {
	font-size: 12px;
	font-weight: 500;
	color: #ff4d4f;
}
.info-payments-detail {
	font-size: 12px;
	font-weight: 400;
	color: #748ea0;
}

.title-principal-data-new {
	font-size: 14px;
	font-weight: 600;
	color: #2f4858;
	margin-bottom: 8px;
}

.collapse-data {
	.ant-collapse-content {
		border-top: 0.5px solid #2f4858 !important;
	}
	.ant-collapse-item {
		border-bottom: none !important;
	}
	.ant-collapse-content-box {
		padding: 0px !important;
	}
}
.collapse-data-blue {
	.ant-collapse-content {
		border-top: 0.5px solid #036c9c !important;
	}
	.ant-collapse-item {
		border-bottom: none !important;
	}
}

.collapse-data-yellow {
	.ant-collapse-content {
		border-top: 0.5px solid #ffc069 !important;
	}
	.ant-collapse-item {
		border-bottom: none !important;
	}
}

.collapse-data-green {
	.ant-collapse-content {
		border-top: 0.5px solid #05c69a !important;
	}
	.ant-collapse-item {
		border-bottom: none !important;
	}
}
.collapse-data-red {
	.ant-collapse-content {
		border-top: 0.5px solid #ff4d4f !important;
	}
	.ant-collapse-item {
		border-bottom: none !important;
	}
}

.gb-form-edita-data {
	.ant-col {
		display: flex;
		flex-direction: column;
	}
	.ant-form-item {
		margin-top: 0 !important;
		margin-bottom: 16px !important;
		
		.ant-form-item-label {
			line-height: 16px;

			label {
				font-size: 12px;
				color: #2f4858;
				font-weight: 400;
			}
		}

		.ant-input-affix-wrapper {
			height: 40px !important;
			color: #748ea0;
			font-size: 14px;
			line-height: 20px;
			width: 100%;
			border-color: #c0daee;
			border-radius: 4px;

			input {
				height: 30px;
			}
		}

		.ant-form-item-control {
			.ant-input-affix-wrapper {
				input {
					height: 30px !important;
					;
				}
			}

			input {
				height: 40px !important;
				color: #2f4858;
				font-size: 14px;
				line-height: 20px;
				width: 100%;
				border-color: #c0daee;
				border-radius: 4px;
			}

			.ant-radio-wrapper {
				color: #2f4858;
				font-size: 12px;
				font-weight: 400;
			}

			.ant-checkbox-group {
				label {
					color: #748ea0;
					font-size: 14px !important;
					font-weight: 400;
				}
			}

			.ant-radio-group {
				label {
					color: #748ea0;
					font-size: 14px !important;
					font-weight: 400;
				}
			}

			.ant-form-item-explain-error {
				font-size: 12px;
				margin-top: 8px;
			}

			.ant-form-item-control-input-content {
				textarea {
					color: #2f4858;
					font-size: 14px !important;
				}
			}

			.ant-select-arrow  {
				color: #2f4858;
			}

			.ant-select-selector, .ant-select-single {
				height: 40px !important;
				border-radius: 4px !important;
				width: 100%;
				border-color: #c0daee;
				
				.ant-select-selection-item {
					align-self: center;
					font-size: 14px;
					color: #2f4858;
				}

				.ant-select-selection-placeholder {
					height: 40px !important;
					font-size: 14px;
					padding: 4px 0;
				}
			}
		}
	}
}
