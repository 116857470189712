.reco-steps {
  .ant-steps-item-container {
    position: relative; 
    min-width: 130px;
  }

  .ant-steps-item-tail { 
    border-top: 1px solid #2F4858 !important;
    padding: 0px !important;
    z-index: 1; 
    margin-top: 3px;
  }

  .ant-steps-item-icon {
    z-index: 2; 
    background-color: white !important; 
    color: #2F4858 !important; 
    border: 1px solid #2F4858 !important;
    position: absolute;
    font-size: 16px;
    font-weight: 600;
  }

  .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #2F4858 !important; 
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: #2F4858 !important; 
  }

  .ant-steps-item-title {
    margin-top: 40px;
    color:  #2F4858 !important;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    height: 48px;
    min-width: 130px;
  }

  .ant-steps-item:nth-child(1) .ant-steps-item-tail,  
  .ant-steps-item:nth-child(2) .ant-steps-item-tail { 
    border-top: 1px dashed #2F4858 !important;
  }
  
  .ant-steps-item:nth-child(2) .ant-steps-item-icon {
    border: 1px dashed #2F4858 !important;
  }

  .ant-steps-item-description {
   min-width: 130px;
  }
}

.button-reco {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
}

.text-button-reco {
  font-size: 14px;
  font-weight: 500; 
  color: #006DFF;
  cursor: pointer;
}

.text-button-disabled-reco{
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500; 
  color: #748EA0;
  cursor: not-allowed;
}

.icon-button-reco{
  font-size: 18px;
   color: #006DFF;
   margin-top: -3px;
}

.form-reco {
  display: flex;
  flex-direction: column;
  gap: 8px;
  input{
    color: #2F4858;
  }
  input[disabled] {
    color: #2F4858 !important;
  }
  input::placeholder {
    color: #748EA0 !important;
    font-size: 12px;
  }
  textarea{
    color: #2F4858;
  }
  textarea::placeholder {
    color: #748EA0 !important;
    font-size: 12px
  }
  .ant-picker-disabled {
    background-color: #F1F7F8 !important;
    border-color: #C0DAEE !important;
    .ant-picker-suffix {
      color: #748EA0;
    }
  }
  .ant-input-number-input-wrap::after {
    top: -5px !important;
  }
}

.label-reco {
  color: #2F4858;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}

.modals-reco {
  .ant-modal-close {
		color: #02C3CD;
		margin-top: 25px;
		margin-right: 20px;
	}

	.ant-modal-content {
		padding: 40px !important;
    border-radius: 4px !important;
	}

	.ant-modal-header {
		padding-bottom: 16px;
		.ant-modal-title {
			color: #2F4858 !important;
			font-size: 16px;
			font-weight: 600;
      max-width: 95%;
		}
	}

	.ant-modal-footer {
		width: 100%;
		display: flex;
		margin-top: 32px;
    justify-content: center;

		.ant-btn{
			width: 50%;
			height: 40px;
		}

		.ant-btn-default {
			border-color: #02C3CD !important;
			color: #02C3CD !important;
		}
	}
}

.modals-reco-error {
  .ant-modal-footer {
    gap: 8px; 

    .ant-btn {
      max-width: 190px;
    }
  }
}

.datepicker-reco {
  border-radius: 4px;
  width: 130px;
  border-color: #C0DAEE;
  width: 130px;

  .ant-picker-suffix {
    color: #748EA0;
  }
}

.select-reco {
  .ant-select-selector {
    border-color: #C0DAEE !important;
    color: #2F4858 !important;
    border-radius: 4px !important;
    height: 32px !important;
  }
  .ant-select-selection-placeholder {
    color: #748EA0;
    font-size: 12px;
  }
  .ant-select-arrow {
    color: #748EA0;
  }
}

.input-reco {
  .ant-input {
    border-color: #C0DAEE;
    color: #748EA0 !important;
    width: 130px !important;
    border-radius: 4px !important;
  }
}

.checkbox-reco {
  font-size: 14px;
  font-weight: 400;
  color: #748EA0;
  margin-top: 8px; 
  .ant-checkbox-inner {
    border-color: #C0DAEE;
  }
}

.notification-info-reco {
  width: fit-content !important;
  border-radius: 4px;
  border: 1px solid  #1890FF;
  background: #D4EAFF !important;
  border-radius: 4px !important;

  .ant-notification-notice-message {
    color: #126CBF !important;
    margin-inline-start: 24px !important;
  }

  .ant-notification-notice-description {
    color: #2F4858 !important;
    margin-inline-start: 24px !important;
  }

  .ant-notification-notice-icon {
    color: #126CBF !important;
    font-size: 18px !important;
    align-self: center; 
    margin-top: 4px;
  }
}

.notification-sucess-reco {
  width: fit-content !important;
  border-radius: 4px;
  border: 1px solid  #52C41A;
  background: #DFF4D4 !important;
  border-radius: 4px !important;
  min-width: 360px;

  .ant-notification-notice-message {
    color: #3E9314 !important;
    margin-inline-start: 24px !important;
  }

  .ant-notification-notice-description {
    color: #2F4858 !important;
    margin-inline-start: 24px !important;
  }

  .ant-notification-notice-icon {
    color: #52C41A !important;
    font-size: 18px !important;
    align-self: center; 
    margin-top: 4px;
  }
}

.notification-error-reco {
  width: fit-content !important;
  border-radius: 4px;
  border: 1px solid  #FF4D4F;
  background: #FFDEDE !important;
  border-radius: 4px !important;

  .ant-notification-notice-message {
    color: #FF4D4F !important;
    margin-inline-start: 24px !important;
  }

  .ant-notification-notice-description {
    color: #2F4858 !important;
    margin-inline-start: 24px !important;
  }

  .ant-notification-notice-icon {
    color: #FF4D4F !important;
    font-size: 18px !important;
    align-self: center; 
    margin-top: 4px;
  }
}

.list-reco {
  color: #2F4858;
  font-size: 12px;
  font-weight: 400;
  margin-left: -24px;
  line-height: 2;
  margin-top: -5px;
  margin-bottom: 0px;
  li::marker {
    font-weight: 600;
  }
}

.form-formalization {
  input{
    color: #2F4858;
  }

  input::placeholder {
    color: #748EA0 !important;
    font-size: 12px;
  }
}

.label-formalization {
  color:  #2F4858;
  font-size: 14px;
  font-weight: 400;
}

.input-formalization {
  .ant-input, .ant-input-number-input {
    border-color: #C0DAEE;
    color: #2F4858 !important;
    width: 130px !important;
    border-radius: 4px !important;
    max-width: 350px;
    border-radius: 4px !important;
  }
}