/*
@import '~antd/dist/antd.less';
*/
@import './styles/sections/banks/banks.less';
@import './styles/sections/appraisal/appraisal.less';
@import './styles/sections/resume-data/resume.less';
@import './styles/sections/Financial-page/financial.less';
@import './styles/skeleton.less';
@import './styles/sections/graphics/waterProgress.less';
@import './styles/sections/Certificate/Certificate.less';
@import './styles/sections/mortgage-documents/documents.less';
@import './styles/sections/banks/emailPageSendBank.less';
@import './styles/sections/search/search.less';
@import './styles/sections/calendar/calendar.less';
@import './styles/sections/promoter/promoter.less';
@import './styles/sections/recommenders/recommenders.less';
@import './styles/sections/mortgage/mortgage.less';
@import './styles/sections/recos/recos.less';

body {
	background: rgb(250, 254, 254);
	margin: 0px;
	padding: 0px;
}

:where(.css-dev-only-do-not-override-1hqp3bl).ant-modal .ant-modal-content {
	padding: 0px;
}

.ant-modal-close:hover {
	background-color: #fff !important;
}

.ant-table {
	a {
		color: #02c3cd !important;
	}
}

.shadowed {
	.ant-input,
	.ant-select-selector,
	.ant-input-number-input {
		background-color: #effcfc !important;
		border: 1px solid #c0daee !important;
	}
}

.ant-table-filter-dropdown {
	.ant-input {
		border-radius: 0px !important;
	}

	.ant-btn {
		border-radius: 0px !important;
	}

	&-btns {
		.ant-btn-link {
			color: #02c3cd !important;

			&:disabled {
				color: rgba(0, 0, 0, 0.25) !important;
			}
		}
	}
}

.ant-modal-content {
	border-radius: 0px !important;
}

.ant-list-item {
	a {
		color: #02c3cd !important;
	}
}

.ant-menu-item-selected {
	margin-inline: 0px !important;
	width: 100% !important;
	border-radius: 0px !important;
}

.ant-input-number {
	height: 32px;
	text-align: left;
	font-size: 12px;
	outline: 0;
	border: 1px solid #c0daee;
}

.ant-input-number:hover {
	border-color: #02c3cd !important;
}

.ant-input:hover {
	border-color: #02c3cd !important;
}

.afrodita.gb-input-number:hover {
	border-color: #02c3cd !important;
}

.ant-select-selector:hover {
	border-color: #02c3cd !important;
}

.trigger {
	font-size: 18px;
	line-height: 64px;
	padding: 0 24px;
	cursor: pointer;
	transition: color 0.1s;
}

.trigger:hover {
	color: #1890ff;
}

.ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #02c3cd !important;
}

.ant-tabs-nav .ant-tabs-ink-bar {
	background-color: #02c3cd !important;
}

.ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-nav .ant-tabs-tab:hover .ant-tabs-tab-btn {
	color: #02c3cd !important; /* Cambiar el color del texto */
}

/*LOGO*/

.logo {
	width: 100%;
	padding: 35px 35px;
	background-color: white;
	overflow: hidden;
}

.logoS {
	width: 100%;
	background-color: white;
	overflow: hidden;
}
.logoS img {
	width: 90%;
}

.logo img {
	width: 100%;
}

.ant-layout-sider-collapsed .logo {
	padding: 7px;
}

.lang-avatar {
	background-color: @primary-color !important;
}

.ant-card {
	.h1-tabpane {
		background-color: #fff;
	}
}

.fc-time-grid .fc-content-skeleton {
	position: absolute;
	z-index: 3;
	top: 0;
	left: 0;
	right: 0;
	height: 100% !important;
}

.elementList {
	padding: 16px 24px !important;
	color: #f0f0f0 !important;
	margin: 4px 12px !important;
	border-radius: 4px !important;
}

.small-font-size {
	font-size: 80%;

	select,
	span {
		font-size: 80%;
	}
}

.documents-list-row-completed {
	background-color: #effcfc;
}

.documents-list-colunm-completed {
	background-color: #d7e2d5;
}

.documents-list-row-error {
	background-color: #ffdede;
}

.operations-data-page {
	.ant-card:hover {
		background-color: #fafafa;
		z-index: 1;
	}

	.ant-card-head {
		background: #fafafa;
	}
}

.stageSteps {
	.ant-steps-item-content {
		width: 100% !important;
		margin-left: 10px;
		margin-right: 10px;
	}
}

.ant-btn {
	border-radius: 4px !important;
}

.ant-select-selector {
	border-radius: 0px !important;
}

.button-primary {
	border-color: #02c3cd;
	color: white;
	background-color: #02c3cd;
}

.button-primary:hover {
	border-color: #02c3cd;
	color: white;
	background-color: #02c3cd !important;
	opacity: 0.8;
}

.hover-primary:focus,
.hover-primary:hover {
	border-color: #02c3cd !important;
}

.btn-hover:hover {
	color: #02c3cd !important;
}

.button-primary-danger-gibobs {
	// border-color: #02c3cd;
	border-radius: 4px;
	// color: #02c3cd;
	width: 88px;
	height: 24px;
	font-size: 14px;
	font-weight: 500;
	padding: 0 0;
}

.button-secundary-inside-gibobs {
	border-color: #02c3cd;
	border-radius: 4px;
	color: #02c3cd;
	background-color: white;
	width: auto;
	font-size: 12px;
	font-weight: 500;
}

.button-sabadell {
	background-color: #006dff;
	color: white;
	border-radius: 4px;
	border-color: #006dff;
	border-radius: 4px;
	font-size: 14px;
	font-weight: 500;
	padding: 0 0;
}

.button-sabadell:hover {
	border-color: #006dff !important;
	color: white !important;
	opacity: 0.8;
}
.ant-popover-inner {
	.ant-btn-primary {
		background-color: #02c3cd;
	}
	.ant-btn-primary:hover {
		background-color: #02c3cd !important;
		opacity: 0.8;
	}
	.ant-btn-default:hover {
		border-color: #02c3cd !important;
		color: #02c3cd !important;
	}
}

.ant-pagination-item-active {
	border-color: #02c3cd !important;
	a {
		color: #02c3cd !important;
	}
}

.ant-btn-dashed {
	border-style: solid;
}

.stepFiveOtherLanguage {
	.ant-steps-item-description {
		margin-left: -60px;
		width: 80px;
	}
}

.stepSixOtherLanguage {
	.ant-steps-item-description {
		margin-left: -60px;
		width: 80px;
	}
}

.gb-check-box-label-12 {
	.ant-form-item {
		.ant-form-item-control {
			.ant-checkbox-group {
				label {
					font-size: 12px !important;
				}
			}
		}
	}
}

.stepSevenOtherLanguage {
	.ant-steps-item-container {
		width: 150px;
		align-items: center !important;
		align-content: center !important;
	}

	.ant-steps-item-description {
		width: 80px;
		margin-left: -60px !important;
		text-align: center !important;
		align-items: center !important;
		align-content: center !important;
	}
}

.stepOneBasic {
	.ant-steps-item-icon {
		margin-left: 10px;
	}

	.ant-steps-item-tail {
		margin-left: -10px;
	}

	.ant-steps-item-description {
		margin-left: -48px !important;
		width: 60px !important;
		text-align: center !important;
		margin-top: 35px;
	}
}

.stepTwoBasic {
	.ant-steps-item-description {
		margin-left: -42px;
		width: 50px !important;
		text-align: center !important;
		margin-top: 35px;
	}
}

.stepTwoBasic2 {
	.ant-steps-item-description {
		margin-left: -47px !important;
		width: 50px !important;
		text-align: center !important;
		margin-top: 35px;
	}
}

.stepThreeBasic {
	.ant-steps-item-container {
		width: 7px;
		margin-left: 7px;
	}

	.ant-steps-item-description {
		margin-left: -44px;
		width: 70px;
		text-align: center !important;
		margin-top: 35px;
	}
}

.stepFourBasic {
	.ant-steps-item-icon {
		margin-left: 10px;
	}

	.ant-steps-item-container {
		width: 80px;
		margin-left: 7px;
	}

	.ant-steps-item-description {
		margin-left: -69px;
		width: 100px;
		text-align: center !important;
		margin-top: 35px;
	}
}

.stepFiveBasic {
	.ant-steps-item-icon {
		margin-left: 10px;
	}

	.ant-steps-item-description {
		margin-left: -68px;
		width: 100px;
		text-align: center !important;
		margin-top: 35px;
	}
}

.stepSixBasic {
	.ant-steps-item-description {
		margin-left: -40px;
		width: 80px;
		margin-top: 35px;
	}
}

.stepSevenBasic {
	.ant-steps-item-description {
		margin-left: -40px;
		width: 60px;
		margin-top: 35px;
	}
}

.stepSevenBasic1 {
	.ant-steps-item-description {
		margin-left: -40px;
		width: 100px;
		margin-top: 35px;
	}
}

.stepEightBasic {
	.ant-steps-item-description {
		margin-left: -45px;
		width: 80px;
		margin-top: 35px;
	}
}

.stepTwo {
	.ant-steps-item-description {
		margin-left: -25px;
		width: 50px !important;
		text-align: left !important;
	}
}

.stepThree {
	.ant-steps-item-description {
		margin-left: -52px;
		width: 70px;
		text-align: left !important;
	}
}

.stepFour {
	.ant-steps-item-description {
		margin-left: -62px;
		width: 100px;
	}
}

.stepFive {
	.ant-steps-item-description {
		margin-left: -64px;
		width: 100px;
	}
}

.stepSix {
	.ant-steps-item-description {
		margin-left: -72px;
		width: 100px;
	}
}

.stepSeven {
	.ant-steps-item-description {
		margin-left: -70px;
		width: 100px;
	}
}

.CardSnoozeComponent {
	border: 1px solid #ff7a7b;
	background-color: #ffdede;
	border-radius: 4px;
	margin-top: 24px;

	.ant-card-body {
		padding: 16px;
	}
}

.CardFinishComponent {
	border: 1px solid #52acff;
	background-color: #d4eaff;
	border-radius: 4px;
	margin-top: 24px;

	.ant-card-body {
		padding: 16px;
	}
}

.scrollStepTypeMortgages {
	.ant-steps-item-icon {
		width: 20px;
		height: 20px;

		.ant-steps-icon {
			display: none;
		}
	}

	.ant-steps-item-description {
		font-size: 10px;
	}

	.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
		top: -8px;
		font-size: 10px;
	}

	.ant-steps-item-finish .ant-steps-item-icon {
		margin-top: 6px;
	}

	.ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon {
		margin-top: 6px;
	}

	.ant-steps-item-wait .ant-steps-item-icon {
		margin-top: 6px;
	}
}

//Start types of modals
.ModalCommon {
	.ant-modal-title {
		font-size: 16px;
		font-weight: 600;
		color: #2f4858;
	}

	.ant-modal-header {
		border-bottom: none;
		padding-left: 40px;
		padding-top: 40px;
		padding-bottom: 38px;
		padding-right: 40px;
		border-radius: 4px;
	}

	.ant-modal-body {
		padding-left: 40px;
		padding-right: 40px;
		padding-top: 0px;
		border-radius: 4px;
	}

	.ant-modal-footer {
		padding-top: 0;
		padding-right: 40px;
		padding-bottom: 40px;
		border-top: none;
		border-radius: 4px;
	}

	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 22px;
		padding-right: 38px;
	}
}

.ModalCommonConfirmPaid {
	.ant-modal-body {
		padding-left: 32px;
		padding-right: 32px;
		padding-top: 32px;
	}

	.ant-modal-confirm-title {
		font-size: 14px;
	}

	.ant-modal-confirm-content {
		font-size: 14px;
		margin-top: 16px;
	}

	.ant-col {
		label {
			font-size: 12px;
		}
	}
}

.ModalCommonSendMessage {
	.ant-input {
		border-color: #c0daee;
		border-radius: 4px;
		font-weight: normal;
		font-size: 12px;
		color: #2f4858;
	}

	.ant-form-item-label {
		label {
			font-weight: normal;
			font-size: 12px;
			color: #2f4858;
		}
	}
}

.ModalCommonTitulares {
	.ant-modal-footer {
		padding-top: 30px;
	}
}

.ModalCommonAircall {
	.ant-modal-body {
		padding-left: 76px;
		padding-right: 0;
		padding-top: 70px;
	}
}

.ModalFilter {
	.ant-modal-title {
		color: #02c3cd;
		background-color: #effcfc;
	}

	.ant-modal-header {
		background-color: #effcfc;
	}

	.ant-modal-body {
		padding-left: 76px;
		background-color: #effcfc;
		padding-top: 70px;
	}

	.ant-modal-footer {
		background-color: #effcfc;
	}
}

.ModalToFileOffer {
	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 15px;
	}

	.ant-modal-body {
		padding: 32px;
		padding-right: 52px;
	}

	.ant-modal-footer {
		width: 100%;
		text-align: center;
		border-top: none;
		padding-top: 0px;
		padding-bottom: 32px;
	}
}

.ModalReassign {
	.ant-modal-footer {
		padding-right: 0px !important;
	}
}

.ModalDownloadDocs {
	.ant-btn {
		border-radius: 0px !important;
	}

	.ant-btn-primary {
		background-color: #02c3cd !important;
	}

	.ant-btn-default:hover {
		color: #02c3cd !important;
		border-color: #02c3cd !important;
	}
}

.ModalQR {
	.ant-modal-header {
		padding: 16px;
		padding-left: 24px;
		border-bottom: 1px solid #f0f0f0;
	}

	.ant-modal-footer {
		padding: 10px;
		border-top: 1px solid #f0f0f0;
	}
}

.ModalLastCom {
	.ant-modal-header {
		padding-bottom: 16px !important;
	}
	.ant-modal-body {
		padding-bottom: 40px !important;
	}
}

.ModalRecommender {
	.ant-modal-close {
		color: #02c3cd;
		margin-top: 25px;
		margin-right: 20px;
	}
	.ant-modal-content {
		padding: 40px !important;
	}
	.ant-modal-header {
		border-bottom: 1px solid #c0daee;
		padding-bottom: 16px;
		.ant-modal-title {
			color: #2f4858 !important;
			font-size: 16px;
			font-weight: 600;
		}
	}
	.ant-modal-footer {
		width: 100%;
		display: flex;
		gap: 16px;
		margin-top: 32px;
		.ant-btn {
			width: 50%;
			height: 40px;
		}
		.ant-btn-default {
			border-color: #02c3cd !important;
			color: #02c3cd !important;
		}
	}
}
//End types of modals

.pickerStyle {
	.ant-picker-input {
		> input {
			font-size: 12px;
			color: #02c3cd;
		}

		.ant-picker-suffix {
			color: #02c3cd;
		}
	}
}

.buttonOperations {
	background-color: #effcfc;
	color: #02c3cd;
	font-weight: 400;
	font-size: 12px;
	border: none;
	border-bottom: none;
	height: 24px;
}

.gcard {
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);

	.ant-form label {
		font-size: 10px;
	}

	.ant-input {
		height: 32px;
		border-radius: 4px;
		border-color: #c0daee;
		font-size: 14px;
		width: 280px;
	}

	.ant-input-number-input {
		height: 32px;
		padding: 0 11px;
		text-align: left;
		font-size: 14px;
		outline: 0;
	}
}

.inputCommon {
	.ant-input {
		height: 32px;
		border-radius: 4px;
		border-color: #c0daee;
		font-size: 12px;
		width: 100%;
		text-align-last: center;
		border: 1px solid #c0daee;
	}

	.ant-input-number {
		height: 32px;
		padding: 0 11px;
		text-align: left;
		font-size: 12px;
		outline: 0;
		border: 1px solid #c0daee;
	}
}

.CardNotificationTask {
	border: 1px solid #c0daee;
	border-radius: 4px;
	background: white;

	.ant-card-body {
		padding: 24px !important;
	}
}

.CardTaskGeneral {
	border-radius: 4px;
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);

	.ant-card-body {
		padding: 32px;
	}
}

.CardTaskOnly {
	background: #ffffff;
	border: 1px solid #c0f0f3;
	border-radius: 4px;

	.ant-card-body {
		padding: 24px !important;
	}
}

.card-gibobs-blue {
	width: 100%;
	height: auto;
	background: #effcfc;
	border-color: 1px solid #c0daee;
	border-radius: 4px;
}

.card-gibobs-blue-inside {
	width: 100%;
	height: auto;
	background: white;
	border: 1px solid #c0daee;
	border-radius: 4px;
}

.buttonMasMinus {
	float: right;
	background: #effcfc;
	border: none;
	cursor: pointer;
}

.button-hover {
	border: none;
	background: transparent;

	.button-hover:hover {
		background: transparent;
	}
}

.ant-collapse-content > .ant-collapse-content-box {
	margin-top: -12px;
}

.Card-backgroud-cert {
	width: 100%;
	border: 1px solid #c0f0f3;
	border-radius: 4px;
	background: #effcfc;

	.ant-card-body {
		padding: 24px !important;
	}
}

.Card-backgroud-cert-border-gray {
	border: 1px solid #c0daee;
	border-radius: 4px;
	width: 100%;

	.ant-card-body {
		padding: 24px !important;
	}
}

.cardPersonalShopper {
	.ant-card-head {
		background-color: #02c3cd;
		height: 28px;
	}
}

.card-gibobs-blue {
	width: 100%;
	height: auto;
	background: #effcfc;
	border: 1px solid #c0daee;
	border-radius: 4px;
}

.card-gibobs-blue-inside {
	width: 100%;
	height: auto;
	background: white;
	border: 1px solid #c0daee;
	border-radius: 4px;

	.ant-card-body {
		padding: 24px !important;
	}
}

.buttonMasMinus {
	float: right;
	background: #effcfc;
	border: none;
	cursor: pointer;
}

.button-hover {
	border: none;
	background: transparent;

	.button-hover:hover {
		background: transparent;
	}
}

.ant-collapse-content > .ant-collapse-content-box {
	margin-top: -12px;
}

.button-primari-gibobsNew {
	background-color: #02c3cd;
	border-radius: 4px;
	color: #ffff;
	width: 100%;
	height: 24;
	font-size: 14px;
	text-shadow: none;
	box-shadow: none;
}

.button-primari-gibobs-formRea {
	background-color: rgba(1, 146, 154, 1);
	border-radius: 4px;
	color: #ffff;
	width: auto;
	height: 24px;
	font-size: 12px;
	font-weight: 500;
	text-shadow: none;
	box-shadow: none;
	padding: 0 0;
	border: none;
}

.button-primari-gibobs-formRea:hover {
	background-color: #41d2da !important;
	border-radius: 4px;
	color: #ffff;
	width: auto;
	height: 24px;
	font-size: 12px;
	text-shadow: none;
	box-shadow: none;
	padding: 0 0;
	border: none;
}

.descriptionClosedReason {
	font-size: 12px;
	font-weight: 500;
	color: #1890ff;
	margin-bottom: 0;
	width: 900px;

	p {
		margin-bottom: 0;
	}
}

.gb-resumen-mobile {
	border: 1px solid #c0daee;
	border-radius: 4px;
	height: 100%;

	.ant-card-body {
		padding: 12px;
	}

	.ant-collapse-content-box {
		padding: 12px;
	}
}

.descriptionClosedReason {
	font-size: 12px;
	font-weight: 500;
	color: #1890ff;
	margin-bottom: 0;
	width: 900px;

	p {
		margin-bottom: 0;

		.textTramo {
			font-size: 12px;
			font-weight: 600;
			color: #2f4858;
			align-self: center;
		}
	}
}

.textTramo {
	font-size: 12px;
	font-weight: 600;
	color: #2f4858;
	align-self: center;
}

.inputTramo {
	border: 1px solid #c0daee;
	border-radius: 4px;
	height: 32px;
	font-size: 12px;
	color: #748ea0;
	// margin-left: 16px;
	margin-right: 5px;
	width: 100%;
}

.inputLinkages {
	border: 1px solid #c0daee;
	border-radius: 4px;
	height: 32px;
	font-size: 12px;
	color: #748ea0;
	width: 120px;

	.ant-form-item {
		margin-bottom: 0 !important;
	}
}

.divInputLinkages {
	display: flex;
	// place-content: space-between;
	align-items: center;
	margin-bottom: 16px;

	.ant-form-item {
		margin-bottom: 0 !important;
	}

	.ant-col {
		margin-bottom: 0 !important;
	}
}

.inputFormAnswers {
	.ant-input {
		border-color: #c0daee;
		border-radius: 4px;
		font-size: 12px;
		margin-bottom: 0;
		height: 32px;
	}

	.ant-form-item-label {
		label {
			font-weight: normal;
			font-size: 12px;
			color: #2f4858;
		}
	}

	.ant-col {
		margin-bottom: 0 !important;
	}
}

.selectOfferPage {
	margin-bottom: 0 !important;
}

.inputFormAnswersSelect {
	.ant-dropdown-trigger {
		border-color: #c0daee;
		border-radius: 4px;
		font-size: 12px;
		margin-bottom: 0;
	}

	.ant-form-item-label {
		label {
			font-weight: normal;
			font-size: 12px;
			color: #2f4858;
		}
	}

	.ant-col {
		margin-bottom: 0 !important;
	}
}

.iconOffersTable {
	font-size: 16px;
	margin-right: 5px;
	color: #2f4858 !important;
}
.iconOffersTableDisabled {
	margin-right: 5px;
}

.iconOffersTable:hover {
	color: #02c3cd;
}

.cardStyleOfertPresent {
	border: none;

	.ant-card-body {
		padding: 0;
	}
}

.cardStyleOthers {
	background: #ffff;
	border-radius: 4px;
	border: none;

	.ant-card-body {
		padding: 0;
	}
}

.textStyleTableOfferSelect {
	background-color: #effcfc;
	color: #748ea0;
	font-size: 12px;
}

.textStyleTableOffer {
	color: #748ea0;
	font-size: 12px;
}

.popOverProperty {
	height: 81px;
	width: auto;
	font-size: 12px;
	padding-left: 0;
	padding-right: 0;
}

.selectBankStyle {
	width: 300px;
	height: 100%;

	.ant-select-selector {
		height: 100% !important;
	}
}

.gb-card-general-mortgage {
	.ant-card-body {
		padding: 32px;
	}
}

.ModalCommonConfirm {
	.ant-modal-body {
		padding-left: 32px;
		padding-right: 32px;
		padding-top: 32px;
	}

	.ant-btn {
		color: #02c3cd;
		width: 96px;
		border: 1px solid #02c3cd;
	}

	.ant-btn:hover,
	.ant-btn:focus {
		color: #41d2da;
		background: #fff;
		border-color: #41d2da;
	}

	.ant-btn-dangerous {
		color: #ffffff;
		width: 96px;
		background-color: #ff4d4f;
		border: #ff4d4f;
		text-shadow: none;
		box-shadow: none;
	}

	.ant-btn-dangerous:hover,
	.ant-btn-dangerous:focus {
		color: #fff !important;
		background: #ff7875;
		border-color: #ff7875;
	}

	.ant-modal-confirm-title {
		font-size: 14px;
	}

	.ant-modal-confirm-content {
		font-size: 12px;
		margin-top: 16px;
	}
}

.gb-form-type {
	.ant-col {
		.ant-form-item-label {
			label {
				height: 100%;
				font-size: 10px;
				color: #2f4858;
				font-weight: normal;
			}
		}
	}

	.ant-input {
		height: 32px;
		border-radius: 4px;
		border-color: #c0daee;
		font-size: 12px;
		width: 100%;
		text-align-last: center;
	}
}

.Card-backgroud-cert-border-gray {
	border: 1px solid #c0daee;
	border-radius: 4px;
	width: 100%;

	.ant-card-body {
		padding: 24px !important;
	}
}

.gb-offer-modal {
	.ant-modal-body {
		padding: 32px;
	}

	.gb-form-type {
		.ant-col {
			.ant-form-item-label {
				label {
					height: 100%;
					font-size: 10px;
					color: #2f4858;
					font-weight: normal;
				}
			}
		}
	}

	.ant-input {
		height: 32px;
		border-radius: 4px;
		border-color: #c0daee;
		font-size: 12px;
		width: 100%;
		text-align-last: center;
	}
}

.vertical-tramo-offer {
	.ant-row {
		display: flex;
		flex-flow: row;

		.ant-form-item-label {
			width: 100px;
			margin-top: 15px;
			display: flex;
			align-items: center;
		}
	}
}

.banks-linkages {
	font-size: 12px;
	color: #748ea0;
	width: 170px;
	margin-right: 8px;
}

.analist-list-row-sabadell {
	background-color: #e1f7ff;

	.ant-table-cell-row-hover {
		background-color: #e1f7ff !important;
	}
}

.drawer-pending-notices {
	.ant-drawer-header {
		border-bottom: none;
		padding-bottom: 10px;
	}
	.ant-drawer-body {
		padding-top: 0px;
	}
	.ant-drawer-close {
		position: absolute;
		right: 10px;
	}
}

.card-notification {
	border-radius: 4px;
	.ant-card-body {
		padding: 16px !important;
	}
}

.btn-popover-promoter {
	color: #2f4858;
	font-size: 14px;
	cursor: pointer;
	height: 100%;
	width: 100%;
	border-radius: 8px;
	padding: 4px;
}

.btn-popover-promoter:hover {
	background-color: #effcfc;
}

.link-from-backend {
	color: #02c3cd !important;
}

@primary-color: #02C3CD;@layout-body-background: #FAFEFE;@select-item-selected: white;