.gb-card-24px-16px {
	.ant-card-body {
		padding: 36px !important;
		border-radius: 4px !important;
		box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1) !important;
	}
}

.select-status {
	.ant-select-selector {
		padding: 4px 8px;
		height: auto !important;
		width: auto !important;
		border-radius: 4px !important;
		border: none !important;
		text-align: center;
	}

	.ant-select-arrow {
		color: #2f4858;
	}
	.ant-select-selection-item {
		display: flex;
		align-items: center;
		gap: 6px;
		width: 100%;
		.ant-typography,
		.anticon {
			color: #2f4858 !important;
		}
		.ant-typography {
			margin-top: -2px;
			font-size: 12px;
			font-weight: 500;
		}
	}
}

.select-status-pending {
	.ant-select-selector {
		background: var(--Neutral-L2, #c0daee) !important;
	}
}

.select-status-active {
	.ant-select-selector {
		background: var(--Neutral-L2, #b0e8db) !important;
	}
}

.select-status-blocked {
	.ant-select-selector {
		background: var(--Neutral-L2, #ffdede) !important;
	}
}
.select-status-done {
	.ant-select-arrow {
		color: #0088c6 !important;
	}
	.ant-select-selection-item {
		display: flex;
		align-items: center;
		gap: 6px;
		width: 100%;
		.ant-typography,
		.anticon {
			color: #0088c6 !important;
		}
		.ant-typography {
			margin-top: 2px;
			font-size: 12px;
			font-weight: 500;
		}
	}
}

.one-select-done {
	.ant-select-selector {
		width: 120px !important;
		height: 32px !important;
		margin-left: 8px !important;
		border-color: #c0daee !important;
		border-radius: 4px !important;
	}
}
