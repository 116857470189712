.title-principal {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #2f4858;
}

.title-province {
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	color: #2f4858;
}

.title-operation {
	font-weight: 400;
	font-size: 16px;
	line-height: 100%;
	color: #748ea0;
}

.card-style-gibobs {
	margin-top: 12px;
	border: 1px solid #02c3cd;
	padding: 24px 28px;
	border-radius: 4px;
}

.progress-style {
	.ant-progress-text {
		font-weight: 500;
		font-size: 10px;
		line-height: 12px;
		color: #748ea0;
	}
}

.card-gibobs-promoter {
	box-shadow: 0px 0px 8px rgba(47, 72, 88, 0.1);
	margin-top: 12px;
	width: calc(100% - 40px);
	margin-left: -10px;

	.ant-card-body {
		padding: 32px;
	}
}

.text-detail-small {
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #2f4858;
}

.title-primary {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	color: #2f4858;
}

.paragraph-style {
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	color: #748ea0;
}

.radio-gibobs-promoter {
	margin-bottom: 16px;

	.ant-radio-wrapper {
		color: #2f4858;
		font-size: 14px;
		font-weight: 400;
	}
}

.radio-gibobs-promoter {
	margin-bottom: 16px;

	.ant-radio-wrapper {
		color: #748ea0;
		font-size: 14px;
		font-weight: 400;
	}
}

.sub-title-promoter-gibobs {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #2f4858;
}

.title-project-data {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #2f4858;
}

.paragraph-project-data {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #748ea0;
}

.modal-promoter {
	.ant-modal-header {
		padding: 0px !important;
	}

	.ant-modal-footer {
		text-align: center;
	}
}

.gb-steps-promoter {
	// margin-left: 24px;

	.ant-steps-item-description {
		width: 100%;
		text-align: center;
		font-size: 12px;
	}

	.ant-steps-item-tail {
		width: 9px;
		text-align: center;
	}

	.ant-steps-item-finish {
		.ant-steps-item-icon {
			background: white;
			border-color: #52c41a;
			margin-left: 18px;

			.ant-steps-icon {
				display: inline;
				color: #52c41a;
				top: -2px;
			}
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: #52c41a;
					margin-left: -56px;
				}
			}
		}
	}

	.ant-steps-item-process {
		.ant-steps-item-container {
			.ant-steps-item-icon {
				background: white;
				border: 4px solid #02c3cd;
				margin-left: 10px;
				margin-right: 10px;
			}

			.ant-steps-item-description {
				color: #02c3cd;
				text-align: center !important;
				margin-left: -50px;
			}
		}
	}

	.ant-steps-item-wait {
		.ant-steps-item-icon {
			border: 1px solid rgba(116, 142, 160, 0.5);
			margin-left: 20px;
			margin-right: 5px;

			.ant-steps-icon {
				display: none;
			}
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: rgba(116, 142, 160, 0.5);
					font-weight: normal;
					margin-left: -60px;
				}
			}
		}
	}
}

.step-two-promoter {
	.ant-steps-item-description {
		margin-left: -42px !important;
		width: 50px !important;
		text-align: center !important;
		margin-top: 35px;
	}
}

.step-one-promoter {
	.ant-steps-item-icon {
		margin-left: 10px;
	}

	.ant-steps-item-tail {
		margin-left: -10px;
	}

	.ant-steps-item-description {
		margin-left: -40px !important;
		width: 60px !important;
		text-align: center !important;
		margin-top: 35px;
	}
}

.modal-promoter-footer {
	.ant-modal-footer {
		text-align: center;
	}
}

.dots-class {
	width: 8px;
	height: 8px;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background: #f1f7f8;
}

.dots-class-select {
	width: 8px;
	height: 8px;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border-radius: 50%;
	background: #02c3cd;
}

.container-dots {
	display: flex;
	column-gap: 8px;
	justify-content: center;
	margin-top: 24px;
}

.textStyleTableOfferSelect {
	background-color: #f1f7f8;

	.ant-table-cell-row:hover {
		background: #f1f7f8 !important;
	}
}

.textStyleTable {
	font-size: 12px;
	color: #2f4858;
}

.textStyleTotal {
	font-size: 14px;
	font-weight: 500;
}

.textStyleTotalColor {
	background-color: #effcfc;
}

.table-style-general {
	border: 1px solid #c0daee;

	.ant-table-content {
		margin-left: 0;
	}
}

.title-edit-page-principal {
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #2f4858;
}

.gb-form-profile-headlines-mobile {
	.ant-form-item {
		margin-bottom: 16px;

		.ant-form-item-label {
			line-height: 16px;

			label {
				font-size: 12px;
				color: #2f4858;
				font-weight: 400;
			}
		}

		.ant-input-affix-wrapper {
			height: 40px !important;
			color: #748ea0;
			font-size: 14px;
			line-height: 20px;
			width: 100%;
			border-color: #c0daee;
			border-radius: 4px;

			input {
				height: 30px;
			}
		}

		.ant-form-item-control {
			.ant-input-affix-wrapper {
				input {
					height: 30px !important;
					;
				}
			}

			input {
				height: 40px !important;
				;
				color: #748ea0;
				font-size: 14px;
				line-height: 20px;
				width: 100%;
				border-color: #c0daee;
				border-radius: 4px;
			}

			.ant-radio-wrapper {
				color: #2f4858;
				font-size: 12px;
				font-weight: 400;
			}

			.ant-checkbox-group {
				label {
					color: #748ea0;
					font-size: 14px !important;
					font-weight: 400;
				}
			}

			.ant-radio-group {
				label {
					color: #748ea0;
					font-size: 14px !important;
					font-weight: 400;
				}
			}

			.ant-form-item-explain-error {
				font-size: 12px;
				margin-top: 8px;
			}

			.ant-form-item-control-input-content {
				textarea {
					color: #748ea0;
					font-size: 14px !important;
				}
			}

			.ant-select-selector {
				height: 40px !important;
				width: 100%;
				
				.ant-select-selection-item {
					align-self: center;
					font-size: 14px;
					color: #748ea0;
				}

				.ant-select-selection-placeholder {
					height: 40px !important;
					font-size: 14px;
					padding: 4px 0;
				}
			}
		}
	}
}

.radio-gibobs-promoter {
	margin-bottom: 16px;

	.ant-radio-wrapper {
		color: #2f4858;
		font-size: 14px;
		font-weight: 400;
		margin-right: 76px;
	}
}

.paragraph-economic-data {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #748ea0;
}

.gb-steps-financier {
	// margin-left: 24px;

	.ant-steps-item-description {
		width: 100%;
		text-align: center;
		font-size: 12px;
	}

	.ant-steps-item-tail {
		width: 9px;
		text-align: center;
	}

	.ant-steps-item-finish {
		.ant-steps-item-icon {
			background: white;
			border: 2px solid #02c3cd;
			margin-left: 18px;

			.ant-steps-icon {
				display: none;
				color: #02c3cd;
				top: -2px;
			}
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: #02c3cd;
					margin-left: -46px;
				}
			}
		}
	}

	.ant-steps-item-process {
		.ant-steps-item-container {
			.ant-steps-item-icon {
				background: white;
				border: 2px solid #02c3cd;
				margin-left: 10px;
				margin-right: 10px;
			}

			.ant-steps-item-description {
				color: #02c3cd;
				text-align: center !important;
				margin-left: -50px;
			}
		}
	}

	.ant-steps-item-wait {
		.ant-steps-item-icon {
			border: 1px solid rgba(116, 142, 160, 0.5);
			margin-left: 20px;
			margin-right: 5px;

			.ant-steps-icon {
				display: none;
			}
		}

		.ant-steps-item-container {
			.ant-steps-item-content {
				.ant-steps-item-description {
					color: rgba(116, 142, 160, 0.5);
					font-weight: normal;
					margin-left: -60px;
				}
			}
		}
	}
}

.send-banks-denied-promo {
	.ant-steps-item-icon {
		border: 2px solid #ff4d4f !important;
		border-width: 1px;

		.ant-steps-icon {
			display: none;
		}
	}

	.ant-steps-item-container {
		.ant-steps-item-content {
			.ant-steps-item-description {
				color: #ff4d4f !important;
				margin-left: -60px !important;
			}
		}
	}
}

.send-banks-answered-promo {
	.ant-steps-item-icon {
		border: 2px solid #52c41a !important;
		border-width: 1px;

		.ant-steps-icon {
			display: none;
		}
	}

	.ant-steps-item-container {
		.ant-steps-item-content {
			.ant-steps-item-description {
				color: #52c41a !important;
				margin-left: -60px !important;
			}
		}
	}
}

.send-banks-wait-promo {
	.ant-steps-item-icon {
		border: 2px solid #c0daee !important;
		border-width: 1px;

		.ant-steps-icon {
			display: none;
		}
	}

	.ant-steps-item-container {
		.ant-steps-item-content {
			.ant-steps-item-description {
				color: #c0daee !important;
			}
		}
	}
}

.step-one-basic-financier {
	.ant-steps-item-icon {
		margin-left: 10px;
	}

	.ant-steps-item-tail {
		margin-left: -10px;
	}

	.ant-steps-item-description {
		margin-left: -40px !important;
		width: 60px !important;
		text-align: center !important;
		margin-top: 35px;
	}
}

.modal-promoter-button-style {
	.ant-modal-header {
		padding-bottom: 16px;
	}

	.ant-modal-footer {
		text-align: center;
		padding-left: 40px;
	}
}

.style-form-formalization {
	display: flex;
	flex-direction: column;
	width: 100%;

	.ant-input-number {
		height: 40px;
		width: 100%;
	}

	label {
		font-weight: 400;
		font-size: 12px;
		line-height: 32px;
		color: #2f4858;
		float: left;
		margin-bottom: 8px;
		display: flex;
	}

	input {
		height: 40px;
		color: #748ea0;
		font-size: 14px;
		line-height: 20px;
		width: 100%;
		border-color: #c0daee;
		border-radius: 4px;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border-radius: 4px;
		border-color: #c0daee;
		font-size: 14px;
		height: 40px;
		line-height: 20px;
		color: #748ea0;
		font-weight: 400;

		.ant-select-selection-item {
			align-self: center;
		}
	}

	label::after {
		content: none;
	}
}

.button-disabled-promotor {
	.ant-btn-primary[disabled] {
		background: #c0daee;
		color: #ffffff;
	}
}

.rules-promoter {
	color: red;
	margin-right: 4px;
}

.parent {
	display: grid;
	grid-template-columns: 140px repeat(3, 1fr);
	grid-column-gap: 8px;
	grid-row-gap: 8px;
	margin-bottom: 8px;
	align-items: center;
}

.align-style {
	text-align: right;
}

.card-without-scenarios {
	background-color: #f1f7f8;
	padding: 114px 100px;
	margin-top: 22px;

	.title {
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		color: #2f4858;
	}
}

.update-scenarios-styles {
	display: flex;
	background-color: rgb(239, 252, 252);
	box-shadow: rgb(47 72 88 / 10%) 0px 0px 8px;
	border-radius: 4px;
	padding: 24px;
	border: 1px solid rgb(128, 225, 230);
	justify-content: space-between;
	margin-top: 32px;
	align-items: center;
}

.step-four-promoter {
	.ant-steps-item-icon {
		margin-left: 10px;
	}

	.ant-steps-item-container {
		width: 80px;
		margin-left: 7px;
	}

	.ant-steps-item-description {
		margin-left: -69px !important;
		width: 100px;
		text-align: center !important;
		margin-top: 35px;
	}
}

.selector-radius {
	.ant-select-selector {
		border-radius: 6px !important;
	}
}
	
.button-nda-disabled.ant-btn-dashed:disabled,
.button-nda-disabled.ant-btn-dashed[disabled] {
    background-color: #ECF7FC80 !important;
    color: #C0DAEE !important;
    border: 1px solid #C0DAEE !important;
}

.gb-form-general-data{
	.ant-form-item-control {
		.ant-form-item-control-input-content {
			textarea {
				min-height: 40px;
				border-color: #C0DAEE;
				border-radius: 4px;
			}
			textarea::placeholder{
				padding-top: 4px;
				color: #748EA0;
			}
			.ant-select-selector {
				border-radius: 4px !important;
				border-color: #C0DAEE;
				.ant-select-selection-placeholder {
					color: #748EA0;
				}
			}
			.ant-select-arrow {
				color: #748EA0;
				padding-top: 10px;
			}
		}
	}
}

.modal-change-tab {

	.ant-modal-title {
		font-size: 16px;
		font-weight: 600;
		color: #2f4858;
	}

	.ant-modal-header {
		padding-top: 40px;
		padding-left: 40px;
		padding-right: 40px;
		border-bottom: none;
	}

	.ant-modal-body {
		font-size: 14px;
		font-weight: 400;
		color: #748EA0;
		padding-left: 40px;
		padding-right: 40px;
		padding-bottom: 24px;
	}

	.ant-modal-footer {
		padding-left: 40px;
		padding-right: 40px;
		padding-bottom: 40px;
		border-top: none;
		width: 384px;
		display: flex;
		width: 100%;
		.ant-btn {
			width: 50%;
			height: 40px;
		}
		.ant-btn-default {
			border-color: #02C3CD;
			color: #02C3CD;
		}
	}

	.ant-modal-close-x {
		color: #02c3cd;
		padding-top: 24px;
		padding-right: 40px;
	}
}

.modal-delete {
	.ant-modal-footer {
		justify-content: center;
		.ant-btn {
			width: 90px;
			height: 32px;
		}
	}
}

.form-send-email-promoter{
	.ant-form-item {
		.ant-form-item-label {
			label {
				font-size: 10px;
				font-weight: 400;
				color: #2F4858;
			}
		}	
	}
}
